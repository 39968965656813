export const TRIP_START = {
  es_kc: 'Fecha de Salida',
  es_mx: 'Fecha de Salida',
  es_km: 'Fecha de Salida *',
};

export const TRIP_END = {
  es_kc: 'Fecha de Regreso',
  es_mx: 'Fecha de Regreso',
  es_km: 'Fecha de Regreso *',
};
