import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';

import './assets/css/bootstrap.min.css';
import './styles/styles.scss';
import {PrismicProvider} from '@prismicio/react';
import styles from './App.module.scss';
import Header from './components/Header/Header';
import Router from './routes/Router';
import Location from './components/Location/Location';
import { IntlParent } from './intl';
import Footer from './components/Footer/Footer';
import StateContextParent from './components/StateContextParent/StateContextParent';
import GTM from './components/GTM/GTM';
import HtmlContextParent from './components/HtmlContextParent/HtmlContextParent';
import client from './services/prismic/connect';

function App() {
  const location = window.location.pathname;
  return (
    <div className={styles.app}>
      <ToastProvider placement="bottom-center">
        <PrismicProvider client={client}>
          <BrowserRouter>
            <Location>
              <IntlParent>
                <StateContextParent>
                  <GTM />
                  <HtmlContextParent>
                    <div className={styles.boxApp}>
                      {location !== '/health' && <Header />}
                      <div className={styles.body}>
                        <Router />
                      </div>
                      {location !== '/health' && <Footer />}
                    </div>
                  </HtmlContextParent>
                </StateContextParent>
              </IntlParent>
            </Location>
          </BrowserRouter>
        </PrismicProvider>
      </ToastProvider>
    </div>
  );
}

export default App;
